import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WaitlistSignup from '../components/WaitlistSignup';
import ContentCarousel from '../components/ContentCarousel';
import Modal from '../components/Modal';
import { getRandomContent, addToWaitlist } from '../services/firebase';
import { ContentItem, DraftLotteryVideo, WaitlistFormData, WaitlistData } from '../types';
import FixedCarousel from '../components/FixedCarousel';
import {Widget} from '@typeform/embed-react'; 


const HomePage: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const [email, setEmail] = useState('');
  const [selectedItem, setSelectedItem] = useState<ContentItem | DraftLotteryVideo | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getRandomContent(20);
        setCarouselItems(content);
        
        const tags = Array.from(new Set(content.flatMap(item => item.tags)));
        setAvailableTags(tags);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  const handleWaitlistSignup = async (formData: WaitlistFormData) => {
    try {
      await addToWaitlist(formData);
      alert('Thank you for joining our waitlist!');
    } catch (error) {
      console.error('Error joining waitlist:', error);
      alert('Error joining waitlist. Please try again.');
    }
  };

  const handleItemClick = (item: ContentItem | DraftLotteryVideo) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const handleTagToggle = (tag: string) => {
    setSelectedTags(prev => 
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  const filteredItems = selectedTags.length > 0
    ? carouselItems.filter(item => item.tags.some(tag => selectedTags.includes(tag)))
    : carouselItems;

    return (
      <div className="min-h-screen flex flex-col bg-white text-black">
        <Navbar />
        <header className="relative w-full">
  <div className="relative w-full h-0 pb-[56.25%]"> {/* 16:9 Aspect Ratio */}
    <img 
      className="absolute top-0 left-0 w-full h-full object-cover"
      src={require("../assets/FL_Cover_2.jpg")}
      alt="Fantasy Loser Cover"
    />
    {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30">  */}
     
    {/* </div> */}
  </div>
</header>
        <main className="flex-grow container mx-auto px-4 py-8 flex flex-col items-center">
        {/* <section className="mb-12 w-full max-w-5xl lg:max-w-6xl">
        <br></br>
      <h2 className="text-5xl font-semibold mb-4 text-black text-left font-eau italic">LOSER PUNISHMENT IDEAS</h2>
      {filteredItems.length > 0 ? (
        <ContentCarousel 
          items={filteredItems} 
          onItemClick={handleItemClick}
        />
      ) : (
        <p className="text-center text-gray-400">No content available for selected tags</p>
      )}
    </section> */}
     {/* <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-black drop-shadow-xl font-eau italic text-center">
          Winning is winning and losing is winning, <br></br>when you do it with your friends.
        </h1> */}
        <br></br>
          {/* <section className="mb-12 w-full max-w-5xl lg:max-w-6xl">
          <h2 className="text-5xl font-bold mb-4 text-black text-left font-eau italic">FEATURES COMING SOON</h2>
          <FixedCarousel />
          </section>
          */}
          <br></br>
          
          {/* <h2 className="text-3xl font-semibold mb-4 w-[75%] text-[#f8f8fff] font-eau text-left italic">
          JOIN THE SQUAD!
        </h2> */}

        <h1 className="text-left w-[75%] text-2xl text-bold font-source">Dear Commissioners, </h1>
        <br></br>
      <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">Any fantasy league commissioner knows that their league would be lost without them. We’re looking for the best of the best fantasy football league commissioners to make the cut for our 53 man roster. Is that you? <br></br><br></br> </h2>
          <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">You’ll get early access before the public to our full suite of tools and features like a commissioner hub, loser punishment ideas and a league locker, and you’ll join our panel of 53 commissioner experts where we’ll look to you for critical feedback and insights on new products, features and ideas that only our top 53 can provide. <br></br><br></br></h2>
          <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">Join the Fantasy Loser depth chart by signing up for our wait list and, if you make final cuts, we’ll hit the gridiron together! This is the job application you actually WANT to fill in. <br></br><br></br></h2>
          <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-cedar "> - The FL Team</h2>
          <br></br>
         
          <section className="mb-12 w-full max-w-4xl lg:max-w-5xl bg-[#444444] p-6 rounded-lg shadow-lg h-[40rem]">
          <Widget id="aUOuhTAX" className="w-full h-full"/>
          {/* <WaitlistSignup onSubmit={handleWaitlistSignup} /> */}
        </section>
        </main>
        <Footer />
        <Modal item={selectedItem} onClose={handleCloseModal} />
      </div>
    );
  };
  
  export default HomePage;