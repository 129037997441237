import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { submitFeedback } from '../services/firebase';

const FeedbackForm: React.FC = () => {
  const [feedbackType, setFeedbackType] = useState<'punishment' | 'draft' | 'challenge'>('punishment');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { user } = useAuth();
  const { theme } = useTheme();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      await submitFeedback({
        type: feedbackType,
        title,
        description,
        userId: user.uid,
      });
      alert('Feedback submitted successfully!');
      setTitle('');
      setDescription('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`${theme === 'dark' ? 'bg-[#444444]' : 'bg-gray-100'} p-6 rounded-lg shadow-lg`}>
      <div className="mb-4">
        <label className={`block ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'} text-sm font-bold mb-2`} htmlFor="feedbackType">
          Feedback Type
        </label>
        <select
          id="feedbackType"
          value={feedbackType}
          onChange={(e) => setFeedbackType(e.target.value as 'punishment' | 'draft' | 'challenge')}
          className={`w-full p-2 ${theme === 'dark' ? 'bg-[#555555] text-white' : 'bg-white text-[#333333]'} border ${theme === 'dark' ? 'border-[#18f7b1]' : 'border-[#0fd494]'} rounded`}
        >
          <option value="punishment">Punishment</option>
          <option value="draft">Draft Content</option>
          <option value="challenge">Challenge</option>
        </select>
      </div>
      <div className="mb-4">
        <label className={`block ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'} text-sm font-bold mb-2`} htmlFor="title">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={`w-full p-2 ${theme === 'dark' ? 'bg-[#555555] text-white' : 'bg-white text-[#333333]'} border ${theme === 'dark' ? 'border-[#18f7b1]' : 'border-[#0fd494]'} rounded`}
          required
        />
      </div>
      <div className="mb-4">
        <label className={`block ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'} text-sm font-bold mb-2`} htmlFor="description">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={`w-full p-2 ${theme === 'dark' ? 'bg-[#555555] text-white' : 'bg-white text-[#333333]'} border ${theme === 'dark' ? 'border-[#18f7b1]' : 'border-[#0fd494]'} rounded`}
          required
        />
      </div>
      <button
        type="submit"
        className={`w-full p-2 ${theme === 'dark' ? 'bg-[#18f7b1] text-[#333333]' : 'bg-[#0fd494] text-white'} rounded hover:opacity-80 transition duration-300`}
      >
        Submit Feedback
      </button>
    </form>
  );
};

export default FeedbackForm;