import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ContentCarousel from '../components/ContentCarousel';
import FeedbackForm from '../components/FeedbackForm';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { createLeague, getLeaguesByCommissioner, League } from '../services/firebase';
import Footer from '../components/Footer';

const CommissionerDashboard: React.FC = () => {
  const { user } = useAuth();
  const { theme } = useTheme();
  const [leagues, setLeagues] = useState<League[]>([]);
  const [newLeagueName, setNewLeagueName] = useState('');

  useEffect(() => {
    if (user) {
      fetchLeagues();
    }
  }, [user]);

  const fetchLeagues = async () => {
    if (user) {
      const fetchedLeagues = await getLeaguesByCommissioner(user.uid);
      setLeagues(fetchedLeagues);
    }
  };

  const handleCreateLeague = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user && newLeagueName.trim()) {
      await createLeague({
        name: newLeagueName,
        commissionerId: user.uid,
        commish: user.displayName || 'Unknown',
        leagueOrigin: '',
        recentChamp: '',
        recentLoser: '',
        champsTrophy: '',
        losersTrophy: '',
        bestWin: '',
        worstWin: '',
        mostMovesAvg: '',
        leagueUrl: '',
        notableEvents: '', // Add this line for the new field
        punishment: {
          title: '',
          link: '',
          status: 'pending', // Changed from '' to 'pending'
          selectedPunishment: '',
          customPunishment: '',
        },
        mostRecentLoser: {
          name: '',
          fantasyTeam: '',
          record: '',
          winPct: '',
          totalPoints: '',
          pointsRank: '',
          timesLoser: 0,
          historicalWinPct: '',
          proofOfPunishment: '',
          imageUrl: ''
        },
        deadbeatTracker: [],
        videoLibrary: [],
        playerProfiles: []
      });
      setNewLeagueName('');
      fetchLeagues();
    }
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-[#333333] text-white' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'}`}>
          Commissioner Dashboard
        </h1>
        
        <section className="mb-12 bg-black rounded-lg p-6">
          <h2 className={`text-2xl font-semibold mb-4 text-[#18f7b1]`}>
            Your Leagues
          </h2>
          <form onSubmit={handleCreateLeague} className="mb-6">
            <input
              type="text"
              value={newLeagueName}
              onChange={(e) => setNewLeagueName(e.target.value)}
              placeholder="New League Name"
              className="mr-2 p-2 border rounded text-black w-full md:w-auto mb-2 md:mb-0"
            />
            <button 
              type="submit" 
              className="bg-[#18f7b1] text-black px-4 py-2 rounded hover:bg-[#0fd494] transition duration-300 w-full md:w-auto"
            >
              Create League
            </button>
          </form>
          <ul className="space-y-2">
            {leagues.map((league) => (
              <li key={league.id} className="bg-gray-800 rounded p-3">
                <Link 
                  to={`/league/${league.id}`} 
                  className="text-white hover:text-[#18f7b1] transition duration-300 "
                >
                  {league.name}
                </Link>
              </li>
            ))}
          </ul>
        </section>

 
        <section className="mb-12">
          <h2 className={`text-2xl font-semibold mb-4 ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'}`}>
            Feedback
          </h2>
          <FeedbackForm />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CommissionerDashboard;