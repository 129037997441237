import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import AdminPage from './pages/AdminPage';
import AuthenticatedHome from './pages/AuthenticatedHome';
import PrivateRoute from './components/PrivateRoute';
import CommissionerDashboard from './pages/CommissionerDashboard';
import { ThemeProvider } from './contexts/ThemeContext';
import ContentPage from './pages/ContentPage';
import LeaguePage from './pages/LeagueLocker';
import PasswordResetPage from './pages/PasswordReset';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route 
            path="/admin" 
            element={
              <PrivateRoute 
                component={AdminPage} 
                allowedRoles={['admin']} 
              />
            } 
          />
          <Route 
            path="/authHome" 
            element={
              <PrivateRoute 
                component={AuthenticatedHome} 
                allowedRoles={['admin', 'commissioner']} 
              />
            } 
          />
             <Route 
            path="/dashboard" 
            element={
              <PrivateRoute 
                component={CommissionerDashboard} 
                allowedRoles={['admin', 'commissioner']} 
              />
            } 
          />
              <Route 
            path="/sign-up" 
            element={
              <PrivateRoute 
                component={SignupPage} 
                allowedRoles={['admin']} 
              />
            } 
          />
           <Route 
              path="/content/:id" 
              element={<ContentPage />} 
            />
           <Route path="/league/:id" element={<LeaguePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/reset-password" element={<PasswordResetPage />} />
        </Routes>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;