import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaDiscord, FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase'; // Import the db instance from your firebase.ts file

const Footer: React.FC = () => {
  const { theme } = useTheme();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');

    try {
      console.log('Attempting to add document...');
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email,
        subscribedAt: new Date()
      });
      console.log("Document written with ID: ", docRef.id);
      setMessage('Successfully subscribed!');
      setEmail('');
    } catch (error: any) {
      console.error('Detailed error:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };
  
  return (
    <footer className={`bg-[#18f7b1] text-black py-6`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="w-full md:w-[10rem] mb-6 md:mb-0"></div>
          
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="font-bold mb-2 text-center md:text-left font-eau italic text-2xl">JOIN THE CONVERSATION</h3>
            <div className="flex justify-center md:justify-start space-x-4 mb-4">
              {/* <a href="https://discord.gg/PMbXQjE8r8" target="_blank" rel="noopener noreferrer"><FaDiscord size={48} /></a> */}
              <a href="https://discord.gg/PMbXQjE8r8" target="_blank" rel="noopener noreferrer"><FaInstagram size={48} /></a>
              <a href="https://discord.gg/PMbXQjE8r8" target="_blank" rel="noopener noreferrer"><FaTwitter size={48} /></a>
              <a href="https://discord.gg/PMbXQjE8r8" target="_blank" rel="noopener noreferrer"><FaTiktok size={48} /></a>
            </div>
          </div>

          <div className="w-full md:w-1/3 flex justify-center md:justify-end mt-6 md:mt-0 p-2">
            <ul>
              <li>
                <h3 className="font-bold mb-2 text-center md:text-left font-eau italic">SUBSCRIBE TO OUR NEWSLETTER</h3>
                <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="EMAIL ADDRESS"
                    className="flex-grow px-3 py-2 text-black mb-2 sm:mb-0 sm:mr-2"
                    required
                  />
                  <button 
                    type="submit" 
                    className="bg-black text-white px-4 py-2 w-full sm:w-auto"
                    disabled={isLoading}
                  >
                    {isLoading ? 'SUBMITTING...' : 'SUBMIT'}
                  </button>
                </form>
                {message && <p className="mt-2 text-sm">{message}</p>}
              </li>
            </ul>
          </div>
          
          <img 
            src={require('../assets/FL_Logo_FL_Icon_Blk.png')} 
            alt="FL Logo"
            className="w-[10%] max-w-sm mx-auto h-auto object-contain sm:max-w-lg md:max-w-xl lg:max-w-2xl"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;