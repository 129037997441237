import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { getLeagueById, updateLeague, League, getContentByType } from '../services/firebase';
import Footer from '../components/Footer';

const LeaguePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const { theme } = useTheme();
  const [league, setLeague] = useState<League | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLeague, setEditedLeague] = useState<League | null>(null);
  const [punishments, setPunishments] = useState<Array<{ id: string; title: string }>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      fetchLeague();
      fetchPunishments();
    }
  }, [id]);

  const fetchLeague = async () => {
    if (id) {
      const fetchedLeague = await getLeagueById(id);
      setLeague(fetchedLeague);
      setEditedLeague(fetchedLeague);
    }
  };

  const getPunishmentUrl = (id: string) => {
    return `https://fantasyloser.com/content/${id}`;
  };

  const handlePunishmentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPunishmentId = e.target.value;
    setEditedLeague(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        punishment: {
          ...prev.punishment,
          selectedPunishment: selectedPunishmentId,
          link: selectedPunishmentId ? getPunishmentUrl(selectedPunishmentId) : '',
          title: prev.punishment?.title || '',
          status: prev.punishment?.status || 'pending',
          customPunishment: prev.punishment?.customPunishment || ''
        }
      };
    });
  };

  const fetchPunishments = async () => {
    try {
      setIsLoading(true);
      const punishmentContent = await getContentByType('punishment');
      console.log('Fetched punishments:', punishmentContent);
      const formattedPunishments = punishmentContent.map(item => ({
        id: item.id,
        title: item.title,
        type: item.type,
      }));
      console.log('Formatted punishments:', formattedPunishments);
      setPunishments(formattedPunishments);
    } catch (err) {
      console.error('Error fetching punishments:', err);
      setError('Failed to fetch punishments');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (id && editedLeague) {
      await updateLeague(id, editedLeague);
      setLeague(editedLeague);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setEditedLeague(league);
    setIsEditing(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    nested?: keyof League
  ) => {
    if (!editedLeague) return;

    const { name, value } = e.target;

    setEditedLeague((prev) => {
      if (!prev) return prev;

      if (nested) {
        return {
          ...prev,
          [nested]: {
            ...(prev[nested] as Record<string, unknown>),
            [name]: value
          }
        };
      } else {
        return {
          ...prev,
          [name]: value
        };
      }
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatTitle = (key: string) => {
    return key
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  type DeadbeatStatus = 'PAID' | 'DEADBEAT' | 'HALF DEADBEAT';

  const handleDeadbeatChange = (index: number, field: string, value: string | number) => {
    setEditedLeague((prev) => {
      if (!prev) return prev;
      const newTracker = [...prev.deadbeatTracker];
      if (field === 'status') {
        newTracker[index] = { ...newTracker[index], [field]: value as DeadbeatStatus };
      } else if (field === 'amountOwed') {
        newTracker[index] = { ...newTracker[index], [field]: Number(value) };
      } else {
        newTracker[index] = { ...newTracker[index], [field]: value };
      }
      return { ...prev, deadbeatTracker: newTracker };
    });
  };

  if (!league) {
    return <div>Loading...</div>;
  }

  const canEdit = user && league.commissionerId === user.uid;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-black'}`}>
      <Navbar />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-4 text-center">{league.name}'s Locker</h1>
        
        {canEdit && !isEditing && (
          <button onClick={handleEdit} className="bg-[#18f7b1] hover:bg-black text-black hover:text-white  px-4 py-2 rounded-lg mb-4">
            Edit League
          </button>
        )}

        <div className="space-y-4">
          {/* League Profile section */}
          <div className="bg-[#18f7b1] p-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-2 italic">LEAGUE PROFILE</h2>
            {isEditing ? (
              <>
                {Object.entries(league).map(([key, value]) => {
                  if (key !== 'id' && key !== 'commissionerId' && typeof value === 'string') {
                    return (
                      <div key={key} className="mb-2">
                        <label className="block mt-2">{formatTitle(key)}:</label>
                        <input
                          type="text"
                          name={key}
                          value={editedLeague?.[key as keyof League] as string || ''}
                          onChange={(e) => handleInputChange(e)}
                          className="w-full p-2 text-black rounded"
                        />
                      </div>
                    );
                  }
                  return null;
                })}
                <div className="mb-2">
                  <label className="block mt-2">Notable Events:</label>
                  <textarea
                    name="notableEvents"
                    value={editedLeague?.notableEvents || ''}
                    onChange={(e) => handleInputChange(e)}
                    className="w-full p-2 text-black rounded"
                    rows={4}
                  />
                </div>
              </>
            ) : (
              <>
                {Object.entries(league).map(([key, value]) => {
                  if (key !== 'id' && key !== 'commissionerId' && typeof value === 'string') {
                    return <p key={key} className="mb-2"><strong>{formatTitle(key)}:</strong> {value}</p>;
                  }
                  return null;
                })}
                <p className="mb-2"><strong>Notable Events:</strong> {league.notableEvents || 'N/A'}</p>
              </>
            )}
          </div>

         {/* Punishment section */}
      <div className="bg-black p-4 rounded mt-4">
        <h2 className="text-2xl font-bold mb-2 text-[#18f7b1] italic">PUNISHMENT</h2>
        {isEditing ? (
          <>
            <div>
              <label className="block mt-2 text-white">Punishment Title:</label>
              <input
                type="text"
                name="title"
                value={editedLeague?.punishment?.title || ''}
                onChange={(e) => handleInputChange(e, 'punishment')}
                className="w-full p-2 text-black rounded"
              />
            </div>
            <div>
              <label className="block mt-2 text-white">Punishment Status:</label>
              <select
                name="status"
                value={editedLeague?.punishment?.status || 'pending'}
                onChange={(e) => handleInputChange(e, 'punishment')}
                className="w-full p-2 text-black rounded"
              >
                <option value="approved">Approved</option>
                <option value="denied">Denied</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <div>
              <label className="block mt-2 text-white">Select Punishment:</label>
              <select
                name="selectedPunishment"
                value={editedLeague?.punishment?.selectedPunishment || ''}
                onChange={handlePunishmentSelect}
                className="w-full p-2 text-black rounded"
              >
                <option value="">Select a punishment</option>
                {punishments.map((punishment) => (
                  <option key={punishment.id} value={punishment.id}>{punishment.title}</option>
                ))}
              </select>
            </div>
            {editedLeague?.punishment?.selectedPunishment && (
              <div className="mt-2">
                <a
                  href={getPunishmentUrl(editedLeague.punishment.selectedPunishment)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#18f7b1] hover:bg-black text-black hover:text-white font-bold py-2 px-4 rounded inline-block"
                >
                  View Selected Punishment
                </a>
              </div>
            )}
            <div>
              <label className="block mt-2 text-white">Custom Punishment:</label>
              <textarea
                name="customPunishment"
                value={editedLeague?.punishment?.customPunishment || ''}
                onChange={(e) => handleInputChange(e, 'punishment')}
                className="w-full p-2 text-black rounded"
                rows={4}
              />
            </div>
          </>
        ) : (
          <>
            <p className="text-white"><strong>Punishment Title:</strong> {league.punishment?.title || 'N/A'}</p>
            <p className="text-white"><strong>Punishment Status:</strong> {league.punishment?.status || 'N/A'}</p>
            <p className="text-white">
              <strong>Selected Punishment:</strong> {
                punishments.find(p => p.id === league.punishment?.selectedPunishment)?.title || 'N/A'
              }
            </p>
            {league.punishment?.selectedPunishment && (
              <div className="mt-2">
                <a
                  href={getPunishmentUrl(league.punishment.selectedPunishment)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#18f7b1] hover:bg-[#333333] text-black hover:text-white  font-bold py-2 px-4 rounded inline-block"
                >
                  View Punishment
                </a>
              </div>
            )}
            <p className="text-white"><strong>Custom Punishment:</strong> {league.punishment?.customPunishment || 'N/A'}</p>
          </>
        )}
      </div>
      </div>
          <br></br>

       {/* Most Recent Loser section */}
       <div className="bg-[#18f7b1] p-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-2 italic">MOST RECENT LOSER</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                {isEditing ? (
                  <>
                    {Object.entries(league.mostRecentLoser).map(([key, value]) => (
                      <div key={key} className="mb-2">
                        <label className="block mt-2">{formatTitle(key)}:</label>
                        <input
                          type={key === 'timesLoser' ? 'number' : 'text'}
                          name={key}
                          value={editedLeague?.mostRecentLoser[key as keyof typeof league.mostRecentLoser] || ''}
                          onChange={(e) => handleInputChange(e, 'mostRecentLoser')}
                          className="w-full p-2 text-black rounded"
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {Object.entries(league.mostRecentLoser).map(([key, value]) => (
                      <p key={key} className="mb-2"><strong>{formatTitle(key)}:</strong> {value}</p>
                    ))}
                  </>
                )}
              </div>
              <div>
                {isEditing ? (
                  <input
                    type="text"
                    name="imageUrl"
                    value={editedLeague?.mostRecentLoser.imageUrl || ''}
                    onChange={(e) => handleInputChange(e, 'mostRecentLoser')}
                    className="w-full p-2 text-black"
                    placeholder="Enter image URL"
                  />
                ) : (
                  <img
                    src={league.mostRecentLoser.imageUrl || '/placeholder-image.jpg'}
                    alt="Most Recent Loser"
                    className="w-full h-auto"
                  />
                )}
              </div>
            </div>
          </div>

          <br></br>

          {/* League Video Library section */}
          {/* <div className="bg-[#18f7b1] p-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-2">LEAGUE VIDEO LIBRARY</h2>
            <div className="grid grid-cols-4 gap-4">
              {isEditing ? (
                editedLeague?.videoLibrary.map((video, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={video}
                      onChange={(e) => {
                        const newVideoLibrary = [...(editedLeague?.videoLibrary || [])];
                        newVideoLibrary[index] = e.target.value;
                        setEditedLeague((prev) => prev ? { ...prev, videoLibrary: newVideoLibrary } : null);
                      }}
                      className="w-full p-2 text-black"
                    />
                  </div>
                ))
              ) : (
                league.videoLibrary.map((video, index) => (
                  <div key={index} className="aspect-w-16 aspect-h-9">
                    <iframe
                      src={video}
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title={`League Video ${index + 1}`}
                      className="w-full h-full"
                    ></iframe>
                  </div>
                ))
              )}
            </div>
            {isEditing && (
              <button
                onClick={() => setEditedLeague((prev) => prev ? { ...prev, videoLibrary: [...prev.videoLibrary, ''] } : null)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
              >
                Add Video
              </button>
            )}
          </div> */}

      {/* Deadbeat Tracker section */}
      <div className="bg-black p-4 rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-[#18f7b1] italic">DEADBEAT TRACKER</h2>
      <div className="overflow-x-auto">
        <table className="w-full text-white">
          <thead>
            <tr className="border-b border-[#18f7b1]">
              <th className="py-2 px-4 text-left">PLAYER</th>
              <th className="py-2 px-4 text-left">TEAM</th>
              <th className="py-2 px-4 text-left">AMOUNT OWED</th>
              <th className="py-2 px-4 text-left">DATE PAID</th>
              <th className="py-2 px-4 text-left">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {isEditing ? (
              editedLeague?.deadbeatTracker.map((entry, index) => (
                <tr key={index} className="border-b border-gray-700">
                  <td className="py-2 px-4">
                    <input
                      type="text"
                      value={entry.player}
                      onChange={(e) => handleDeadbeatChange(index, 'player', e.target.value)}
                      className="w-full p-2 text-black rounded"
                    />
                  </td>
                  <td className="py-2 px-4">
                    <input
                      type="text"
                      value={entry.team}
                      onChange={(e) => handleDeadbeatChange(index, 'team', e.target.value)}
                      className="w-full p-2 text-black rounded"
                    />
                  </td>
                  <td className="py-2 px-4">
                    <input
                      type="number"
                      value={entry.amountOwed}
                      onChange={(e) => handleDeadbeatChange(index, 'amountOwed', e.target.value)}
                      className="w-full p-2 text-black rounded"
                    />
                  </td>
                  <td className="py-2 px-4">
                    <input
                      type="date"
                      value={entry.datePaid}
                      onChange={(e) => handleDeadbeatChange(index, 'datePaid', e.target.value)}
                      className="w-full p-2 text-black rounded"
                    />
                  </td>
                  <td className="py-2 px-4">
                    <select
                      value={entry.status}
                      onChange={(e) => handleDeadbeatChange(index, 'status', e.target.value as DeadbeatStatus)}
                      className="w-full p-2 text-black rounded"
                    >
                      <option value="PAID">PAID</option>
                      <option value="DEADBEAT">DEADBEAT</option>
                      <option value="HALF DEADBEAT">HALF DEADBEAT</option>
                    </select>
                  </td>
                </tr>
              ))
            ) : (
              league.deadbeatTracker.map((entry, index) => (
                <tr key={index} className="border-b border-gray-700">
                  <td className="py-2 px-4">{entry.player}</td>
                  <td className="py-2 px-4">{entry.team}</td>
                  <td className="py-2 px-4">${entry.amountOwed.toFixed(2)}</td>
                  <td className="py-2 px-4">{entry.datePaid}</td>
                  <td className="py-2 px-4">{entry.status}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {isEditing && (
        <button
          onClick={() => setEditedLeague((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              deadbeatTracker: [
                ...prev.deadbeatTracker,
                { player: '', team: '', amountOwed: 0, datePaid: '', status: 'PAID' as DeadbeatStatus }
              ]
            };
          })}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Add Deadbeat Entry
        </button>
      )}
    </div>
        {isEditing && (
          <div className="mt-8 flex justify-end space-x-4">
            <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded-lg">
              Save Changes
            </button>
            <button onClick={handleCancel} className="bg-red-500 text-white px-4 py-2 rounded-lg">
              Cancel
            </button>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default LeaguePage;