import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getContentById, addToWaitlist, getUserRole, voteContent, getContentVotes } from '../services/firebase';
import { ContentItem, DraftLotteryVideo, WaitlistFormData } from '../types';
import { useTheme } from '../contexts/ThemeContext';
import { Share2, Skull, Clock, DollarSign, Star, ThumbsUp, ThumbsDown } from 'lucide-react';
import { Widget } from '@typeform/embed-react';
import customRatingIcon from '../assets/FL_Logo_Loser_Icon_Grn.png';
import { useAuth } from '../contexts/AuthContext';

const ContentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [content, setContent] = useState<ContentItem | DraftLotteryVideo | null>(null);
  const { theme } = useTheme();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [upvotes, setUpvotes] = useState(0);
  const [downvotes, setDownvotes] = useState(0);
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id) {
        try {
          const fetchedContent = await getContentById(id);
          if (fetchedContent) {
            setContent({ ...fetchedContent, id });
          } else {
            console.error('Content not found');
          }

          if (user) {
            const role = await getUserRole(user.uid);
            setUserRole(role);
          }

          const votes = await getContentVotes(id);
          setUpvotes(votes.upvotes);
          setDownvotes(votes.downvotes);
          if (user) {
            setUserVote(votes.userVotes[user.uid] || null);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, [id, user]);

  const handleVote = async (voteType: 'up' | 'down') => {
    if (user && id && (userRole === 'commissioner' || userRole === 'admin')) {
      try {
        const result = await voteContent(id, user.uid, voteType);
        setUpvotes(result.upvotes);
        setDownvotes(result.downvotes);
        setUserVote(voteType);
      } catch (error) {
        console.error('Error voting:', error);
      }
    }
  };
  const isContentItem = (item: ContentItem | DraftLotteryVideo): item is ContentItem => {
    return 'type' in item;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!content) {
    return <div>Content not found</div>;
  }

  const CustomRating = ({ rating, maxRating = 5 }: { rating: number; maxRating?: number }) => (
    <div className="flex">
      {[...Array(maxRating)].map((_, index) => (
        <img
          key={index}
          src={customRatingIcon}
          alt={index < rating ? 'Filled rating icon' : 'Empty rating icon'}
          className={`w-10 h-10 ${index < rating ? 'opacity-100' : 'opacity-30'}`}
        />
      ))}
    </div>
  );

  const FLRating = ({ rating }: { rating: string }) => (
    <div className="bg-transparent text-black p-2 sm:p-4 rounded-lg mb-4">
      <h3 className="flex text-4xl sm:text-6xl md:text-8xl font-bold mb-2">
        <img src={require("../assets/FL_Logo_FL_Icon_Blk.png")} className="w-[20%]" alt="FL Logo" />
        RATING:
      </h3>
      <p className="text-5xl sm:text-6xl md:text-7xl font-eau font-bold p-2 sm:p-4 md:p-8 ml-2 sm:ml-5">{rating}</p>
    </div>
  );

  const renderImage = (image: string | File | null, alt: string) => {
    if (typeof image === 'string' && image) {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-200 rounded-lg mb-4 flex items-center justify-center overflow-hidden">
          <img 
            src={image} 
            alt={alt} 
            className="w-full h-full object-contain" 
          />
        </div>
      );
    } else if (image instanceof File) {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-200 rounded-lg mb-4 flex items-center justify-center overflow-hidden">
          <img 
            src={URL.createObjectURL(image)} 
            alt={alt} 
            className="w-full h-full object-contain" 
          />
        </div>
      );
    } else {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-300 rounded-lg mb-4 flex items-center justify-center">
          <span className="text-gray-600">No image available</span>
        </div>
      );
    }
  };

  const handleWaitlistSignup = async (formData: WaitlistFormData) => {
    try {
      await addToWaitlist(formData);
      alert('Thank you for joining our waitlist!');
    } catch (error) {
      console.error('Error joining waitlist:', error);
      alert('Error joining waitlist. Please try again.');
    }
  };

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      
      <main className="flex-grow container mx-auto px-4 py-4 sm:py-8">
        {isContentItem(content) ? (
          <div className="space-y-4 sm:space-y-8">
            <section>
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 font-eau italic">RECIPE TITLE</h1>
              <p className="mb-4 text-3xl">{content.subtitle}</p>
              <div className="flex items-center mb-4">
                <Share2 className="mr-2" />
                <span className="mr-4">SHARE</span>
                {(userRole === 'commissioner' || userRole === 'admin') && (
                  <div className="flex items-center">
                    <button
                      onClick={() => handleVote('up')}
                      className={`mr-2 ${userVote === 'up' ? 'text-green-500' : ''}`}
                    >
                      <ThumbsUp />
                    </button>
                    <span className="mr-2">{upvotes}</span>
                    <button
                      onClick={() => handleVote('down')}
                      className={`mr-2 ${userVote === 'down' ? 'text-red-500' : ''}`}
                    >
                      <ThumbsUp />
                    </button>
                    <span>{downvotes}</span>
                  </div>
                )}
              </div>
            </section>

            <section>
              {renderImage(content.image, content.title)}
              <p className="text-3xl ">{content.imageDescription}</p>
            </section>

            <section className="bg-[#18f7b1] text-black flex flex-col sm:flex-row h-auto sm:h-auto p-4 rounded-xl">
              <div className="flex-1 mb-4 sm:mb-0">
                <FLRating rating={content.flRating || '0'} />
              </div>
              <div className="flex-1 bg-black text-white p-4 rounded-lg">
                <div className="mb-5">
                  <h4 className="text-2xl font-bold mb-2">EMBARRASSMENT LEVEL:</h4>
                  <CustomRating rating={parseInt(content.embarrassmentLevel) || 0} />
                </div>
                <div className="mb-5">
                  <h4 className="text-2xl font-bold mb-2">RISK OF ARREST/DIVORCE/FIRING:</h4>
                  <CustomRating rating={parseInt(content.riskLevel) || 0} />
                </div>
                <div className="mb-5">
                  <h4 className="text-2xl font-bold mb-2">LONG TERM EFFECTS:</h4>
                  <CustomRating rating={parseInt(content.longTermEffects) || 0} />
                </div>
              </div>
            </section>

            <section className="border-black border-4 rounded-xl p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div>
                    <h3 className="text-lg sm:text-xl font-bold mb-2 flex"><img src={require("../assets/FL_Logo_Loser_Icon_Blk.png")} className="w-[5%]" /> EQUIPMENT LOCKER:</h3>
                    <p className="text-sm sm:text-base">{content.equipmentLocker || 'N/A'}</p>
                  </div>
                  <div>
                    <h3 className="text-lg sm:text-xl font-bold mb-2 flex"> <img src={require("../assets/FL_Logo_Loser_Icon_Blk.png")} className="w-[5%]" />EQUIPMENT NEEDED:</h3>
                    <ul className="list-disc list-inside text-sm sm:text-base">
                      {(content.equipmentNeeded || []).map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-start">
                    <Clock className="mr-2 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="text-lg sm:text-xl font-bold">TIME TO COMPLETE:</h3>
                      <p className="text-sm sm:text-base">{content.timeToComplete || 'N/A'}</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <DollarSign className="mr-2 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="text-lg sm:text-xl font-bold">COST TO COMPLETE:</h3>
                      <p className="text-sm sm:text-base">{content.costToComplete || 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="space-y-8">
              <h3 className="text-3xl sm:text-4xl font-bold mb-4">Challenge Instructions:</h3>
              {(content.instructions || []).map((instruction, index) => (
                <div key={index} className="space-y-4">
                  <h4 className="text-2xl sm:text-3xl font-bold text-black">Step {index + 1}</h4>
                  <div className="border-l-4 border-[#18f7b1] pl-4">
                    <p className="mb-2 text-xl sm:text-2xl">{instruction.description}</p>
                    {instruction.image && renderImage(instruction.image, `Instruction ${index + 1}`)}
                  </div>
                </div>
              ))}
            </section>

            <section>
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Ways to Spice It Up:</h3>
              <ul className="list-disc list-inside text-sm sm:text-base">
                {(content.waysToSpiceUp || []).map((way, index) => (
                  <li key={index}>{way}</li>
                ))}
              </ul>
            </section>

            <section className="flex flex-wrap gap-2 mb-4">
              {(content.tags || []).map((tag) => (
                <span key={tag} className={`px-2 py-1 ${theme === 'dark' ? 'bg-[#444444]' : 'bg-gray-200'} text-xs sm:text-sm rounded`}>{tag}</span>
              ))}
            </section>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="w-full h-48 sm:h-64 md:h-96 overflow-hidden rounded-lg">
              <video 
                src={content?.videoUrl} 
                controls 
                className="w-full h-full object-contain"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <p className="text-sm sm:text-base">{content?.description}</p>
          </div>
        )}
      </main>
      <div className="flex justify-center items-center min-h-screen w-full">
        <section className="mb-12 w-full max-w-4xl lg:max-w-5xl bg-[#444444] p-6 rounded-lg shadow-lg h-[40rem]">
          <Widget id="aUOuhTAX" className="w-full h-full"/>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ContentPage;