import React, { useState, useEffect } from 'react';
import { ContentItem, DraftLotteryVideo } from '../types';

interface ContentCarouselProps {
  items: (ContentItem | DraftLotteryVideo)[];
  onItemClick: (item: ContentItem | DraftLotteryVideo) => void;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({ items, onItemClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [items]);

  const nextItems = () => {
    setCurrentIndex((prevIndex) => 
      Math.min(prevIndex + 1, items.length - 1)
    );
  };

  const prevItems = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  if (items.length === 0) {
    return <div className="text-center text-gray-400">No items to display</div>;
  }

  const renderItemContent = (item: ContentItem | DraftLotteryVideo) => {
    if ('videoUrl' in item) {
      // This is a DraftLotteryVideo
      return (
        <div className="flex flex-col h-full justify-between">
          <h3 className="text-lg font-semibold mb-2 text-[#18f7b1] overflow-hidden overflow-ellipsis"
              style={{ wordBreak: 'break-all', overflowWrap: 'break-word', hyphens: 'auto' }}>
            {item.title}
          </h3>
          <p className="text-sm text-gray-300 flex-grow overflow-y-auto"
             style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
            {item.description}
          </p>
          <p className="text-sm text-gray-400 mt-2">Draft Lottery Video</p>
        </div>
      );
    } else {
      // This is a ContentItem
      return (
        <div className="flex items-center justify-center h-full">
          <h3 className="text-2xl font-semibold text-[#18f7b1] text-center"
              style={{ wordBreak: 'break-all', overflowWrap: 'break-word', hyphens: 'auto' }}>
            {item.title}
          </h3>
        </div>
      );
    }
  };

  return (
    <div className="relative px-4 sm:px-6 md:px-8 lg:px-12">
      <div className="overflow-hidden">
        <div 
          className="flex transition-transform duration-300 ease-in-out" 
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((item, index) => (
            <div 
              key={item.id || `item-${index}`} 
              className="w-full h-[15rem] sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex-shrink-0 px-2"
            >
              <div 
                className="bg-[#444444] p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 h-full border border-[#18f7b1] overflow-hidden"
                onClick={() => onItemClick(item)}
              >
                {renderItemContent(item)}
              </div>
            </div>
          ))}
        </div>
      </div>
      {currentIndex > 0 && (
        <button 
          onClick={prevItems} 
          className="absolute top-1/2 left-0 sm:-left-6 md:-left-8 lg:-left-12 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 rounded-full"
          aria-label="Previous"
        >
          <svg width="24" height="24" viewBox="0 0 40 40" className="rotate-180">
            <path d="M15 5 L30 20 L15 35" fill="#00ffc3" stroke="#00ffc3" strokeWidth="1" 
                  filter="drop-shadow(2px 2px 1px rgba(0,0,0,0.3))" />
          </svg>
        </button>
      )}
      {currentIndex < items.length - 1 && (
        <button 
          onClick={nextItems} 
          className="absolute top-1/2 right-0 sm:-right-6 md:-right-8 lg:-right-12 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 rounded-full"
          aria-label="Next"
        >
          <svg width="24" height="24" viewBox="0 0 40 40">
            <path d="M15 5 L30 20 L15 35" fill="#00ffc3" stroke="#00ffc3" strokeWidth="1" 
                  filter="drop-shadow(2px 2px 1px rgba(0,0,0,0.3))" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default ContentCarousel;